import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './styles.less'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

export const Footer = () => {

	let location = useLocation();
	const visible_routes = [ '/login', '/' ];

	return (
		<footer className={`page-footer font-small footer foot ${visible_routes.includes(location.pathname) ? '' : 'd-none'}`}>
			{/* Footer Links */}
			<div className="container text-md-left mt-5">
				{/* Grid row */}
				<div className="row mt-3">
					{/* Grid column */}
					<div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
						{/* Content */}
						<img src='/logo2.png' className='img img-fluid' alt='Proper Training' />
						<hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" />
					</div>
					<div className="col-md-2 col-lg-2 col-xl-2 ml-auto">
						<h6 className="text-uppercase font-weight-bold">Pages</h6>
						<hr
							className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
							style={{ width: 60 }}
						/>
						<p>
							<Link to='/'>Home</Link>
						</p>
						<p>
							<Link to='/asset'>Dashboard</Link>
						</p>
					</div>

					<div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
						<h6 className="text-uppercase font-weight-bold">Company</h6>
						<hr
							className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
							style={{ width: 60 }}
						/>
						<p>
							<Link to="/#about">About Us</Link>
						</p>
					</div>

					<div className="col-md-4 col-lg-3 col-xl-3 ml-auto">
						{/* Links */}
						<h6 className="text-uppercase font-weight-bold">Contact</h6>
						<hr
							className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
							style={{ width: 60 }}
						/>
						<p>
							<i className="fa fa-phone mr-3" /> +44 7405510456
						</p>
						<p>
							<i className="fa fa-envelope mr-3" />
							<span>info@proper-training.com</span>
						</p>
						<p className='d-flex'>
							<i className="fa fa-home mr-3" />
							<span>UNITIVE TECHNOLOGIES LIMITED, 3 Churchill Ct, Manor Royal, Crawley, RH10 9LU.</span>
						</p>
					</div>
					{/* Grid column */}
				</div>
				{/* Grid row */}
			</div>
			{/* Footer Links */}
			{/* Copyright */}

			<div className="container d-md-flex py-4">

				<div className="me-md-auto text-center text-md-start">
					<div className="copyright">
						&copy; 2022 Copyright: <strong><span>UNITIVE TECHNOLOGIES LIMITED</span></strong>. All Rights Reserved
					</div>
				</div>
				<div className="social-links text-center text-md-right pt-3 pt-md-0">
					<a href="https://twitter.com/ProperTrainings" className="twitter">
						<i><FaTwitter /></i>
					</a>
					<a href="https://www.facebook.com/Proper-Training-100494722537846" className="facebook">
						<i><FaFacebookF /></i>
					</a>
					<a href="https://www.instagram.com/properTrainings/" className="instagram">
						<i><FaInstagram /></i>
					</a>
					<a href="https://www.youtube.com/channel/UC5WKEU9-PB9isRO3iSakSgQ" className="google-plus">
						<i><FaYoutube /></i>
					</a>
				</div>
			</div>

			{/* Copyright */}
		</footer>
	);
}
