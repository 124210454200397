import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "antd";
import { toast } from 'react-toastify';
import update from 'immutability-helper';

import useFetch from "../../_fetch";

import "./styles.less";
import { Loader } from "../loader";
import { useNavigate } from "react-router-dom";

function DeleteCourseModal({ selectedCourse, setSelectedCourse, getCourses }) {

	const [loading, setLoading] = useState(false);
	let _fetch = useFetch();

	async function deleteCourse() {
		setLoading(true);
		try {
			let res = await _fetch(`${process.env.REACT_APP_ADMIN_API_URL}/course/${selectedCourse}`, { method: 'DELETE' });
			let result = await res.json();
			if (res.status === 200) {
				toast.success('Course deleted successfully');
				setSelectedCourse('');
				getCourses();
			} else {
				toast.error(result.response);
			}
		} catch (err) {
			console.log(err);
			toast.error('You are offline, please check your internet connection');
		} finally {
			setLoading(true);
		}
	}

	return (
		<Modal
			visible={!!selectedCourse}
			okButtonProps={{ loading }}
			okText='Delete Course'
			onCancel={() => setSelectedCourse('')}
			onOk={deleteCourse}
		>
			Are you sure you want to delete this course?
		</Modal>
	)
}

export function Dashboard() {

	const [courses, setCourses] = useState([]);
	const [totalEnrolled, setTotalEnrolled] = useState([]);
	const [activelyEnrolled, setActivelyEnrolled] = useState([]);
	const [loading, setLoading] = useState([]);
	const [updating, setUpdating] = useState(false);
	const [selectedCourse, setSelectedCourse] = useState('');

	let _fetch = useFetch();
	let navigate = useNavigate();

	function getCourses() {
		_fetch(`${process.env.REACT_APP_ADMIN_API_URL}/course`)
			.then(async (res) => {
				let result = await res.json();
				if (res.status === 200) {
					setCourses(result.response.courses);
					setTotalEnrolled(result.response.total_enrolled);
					setActivelyEnrolled(result.response.actively_enrolled);
				} else {
					toast.error(result.response);
				}
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setLoading(false));
	}

	async function updateCourse(i, status) {
		console.log(i)
		setUpdating(true);
		try {
			let res = await _fetch(`${process.env.REACT_APP_ADMIN_API_URL}/course/${courses[i]._id}`, {
				method: 'PATCH',
				body: { status }
			});
			let result = await res.json();
			if (res.status === 200) {
				toast.success('Course updated successfully!');
				setCourses(update(courses, { [i]: { status: { $set: status } } }));
			} else {
				toast.error(result.message);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setUpdating(false);
		}
	}

	useEffect(() => {
		getCourses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = [
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Total Enrolled',
			render: (e) => totalEnrolled[e._id] ?? 0
		},
		{
			title: 'Actively Enrolled',
			render: (e) => activelyEnrolled[e._id] ?? 0
		},
		{
			title: 'Price',
			render: (e) => `£${e.price}`
		},
		{
			title: 'Author',
			render: (record) => (record.trainer?.name)
		},
		{
			title: 'Actions',
			render: (txt, record, ind) => (
				<React.Fragment>
					<Button className="mx-1">
						View
					</Button>
					<Button className="mx-1" onClick={() => navigate(`/course?id=${record._id}`)}>
						Edit
					</Button>
					<Button danger className="mx-1" onClick={() => setSelectedCourse(record._id)}>
						Delete
					</Button>
					<Button
						loading={updating}
						className="mx-1"
						onClick={() => updateCourse(ind, record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
					>
						Make {record.status === 'ACTIVE' ? 'Inactive' : 'Active'}
					</Button>
				</React.Fragment>
			)
		},
	];

	if (loading) {
		return <Loader />
	}

	return (
		<div className="container py-5">

			<h3 className="d-flex mb-4">
				<span>All Courses</span>
				<Button type="primary" onClick={() => navigate('/course')} className="ms-auto">
					Add Course
				</Button>
			</h3>

			<Table columns={columns} dataSource={courses} />
			<DeleteCourseModal
				selectedCourse={selectedCourse}
				setSelectedCourse={setSelectedCourse}
				getCourses={getCourses}
			/>
		</div>
	);
}
