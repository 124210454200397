import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';

import { login, logout } from "./store/action/user";
import useFetch from './_fetch';
import "./App.less";

import { Dashboard } from "./components/dashboard";
import { Footer } from "./components/footer";
import { Home } from "./components/home";
import Header from "./components/header";
import { Login } from "./components/login";
import { UserInfoForm } from "./components/userInfoForm";
import { Loader } from "./components/loader";
import { AddUpdateCourse } from "./components/course";

export function WithAuth({ Component }) {
	const { _id, admin } = useSelector(st => st.user ?? {});
	if (_id && admin) {
		return <Component />
	}
	return <Navigate to='/' />
}

function App() {

	let dispatch = useDispatch();
	let location = useLocation();
	const mainContent = React.useRef(null);
	const _fetch = useFetch();
	const [cookie, setCookie] = useCookies(['userInfo']);
	const [loading, setLoading] = useState(true);

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		if (mainContent?.current)
			mainContent.current.scrollTop = 0;
	}, [location]);

	const getUserInfo = async () => {
		try {
			let res = await _fetch(`${process.env.REACT_APP_API_URL}/user`);
			if (res.status === 200) {
				res = await res.json();
				setCookie('userInfo', res.response);
				dispatch(login({ ...res.response }));
			} else {
				dispatch(logout());
			}
		} catch (err) {
			console.log(err);
			dispatch(logout());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (cookie.userInfo) {
			dispatch(login({ ...cookie.userInfo }));
		}
		getUserInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return <Loader />
	}

	return (
		<React.Fragment>
			<div ref={mainContent} className="d-flex flex-column h-100">
				<Header />
				<div style={{ zIndex: 5 }} className="flex-fill">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="login" element={<Login />} />
						<Route path="dashboard" element={<WithAuth Component={Dashboard} />} />
						<Route path="course" element={<WithAuth Component={AddUpdateCourse} />} />
						<Route path="home" element={<Home />} />
						<Route path="user_info" element={<WithAuth Component={UserInfoForm} />} />
					</Routes>
				</div>
				<Footer />
			</div>
		</React.Fragment>
	);
}

export default App;
