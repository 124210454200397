import { Button, Form, Input, InputNumber, Spin, Collapse, Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DeleteOutlined, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import useFetch from "../../_fetch";

import './styles.less';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        md: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 19 },
    },
};

const { Panel } = Collapse;

export function AddUpdateCourse() {

    let _fetch = useFetch();
    let search = useLocation().search;

    const [course, setCourse] = useState({});
    const [loading, setLoading] = useState(true);

    const id = new URLSearchParams(search).get("id");

    useEffect(() => {
        if (id) {
            _fetch(`${process.env.REACT_APP_ADMIN_API_URL}/course/${id}`)
                .then(async (res) => {
                    let result = await res.json();
                    if (res.status === 200) {
                        setCourse(result.response);
                    } else {
                        toast.error(result.response);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => setLoading(false));
        } else {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let navigate = useNavigate();

    async function submit(values) {
        try {
            let res = await _fetch(`${process.env.REACT_APP_ADMIN_API_URL}/course/${id}`, {
                method: 'PATCH',
                body: values
            });
            let result = await res.json();
            if (res.status === 200) {
                toast.success('Course updated successfully!');
                navigate('/dashboard');
            } else {
                toast.error(result.message);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const spinIcon = <LoadingOutlined style={{ fontSize: 200, color: '#2717bd' }} spin />;

    if (loading) {
        return (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center" style={{ fontSize: 200 }}>
                <Spin indicator={spinIcon} />
            </div>
        )
    }

    const RichText = ({ value, onChange }) => (
        <ReactQuill
            theme="snow" defaultValue={value}
            onChange={(r) => onChange(r)}
            style={{ borderRadius: 10, marginBottom: 42 }}
        />
    );

    const TopicForm = ({ fields, remove, i }) => (
        <React.Fragment>
            {
                fields.map((topicField, j) => (
                    <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label={
                            <div className="w-100 d-flex justify-content-between">
                                Topic {j + 1}
                                {
                                    fields.length > 1 ?
                                        (
                                            <MinusCircleOutlined
                                                className="dynamic-delete-button"
                                                onClick={() => remove(topicField.name)}
                                            />
                                        )
                                        :
                                        null
                                }
                            </div>
                        }
                        required={false}
                        key={`${i}-topic-${j}`}
                    >
                        <Form.Item
                            {...topicField}
                            {...formItemLayout}
                            label="Topic Title"
                            name={[topicField.name, 'title']}
                            className="label-text-right"
                            key={`${i}-title-${j}`}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            {...topicField}
                            {...formItemLayout}
                            label="Topic Content Type"
                            name={[topicField.name, 'type']}
                            className="label-text-right"
                            key={`${i}-type-${j}`}
                        >
                            <Radio.Group buttonStyle="solid" className="d-flex" >
                                <Radio.Button value="HTML" className="me-2 w-auto">HTML</Radio.Button>
                                <Radio.Button value="VIDEO" className="w-auto">Video</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            {...topicField}
                            {...formItemLayout}
                            label='Concept Video Link'
                            name={[topicField.name, 'url']}
                            className="label-text-right"
                            key={`${i}-url-${j}`}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            {...topicField}
                            {...formItemLayout}
                            label='Concept HTML content'
                            name={[topicField.name, 'html']}
                            className="label-text-right"
                            key={`${i}-html-${j}`}
                        >
                            <RichText />
                        </Form.Item>
                    </Form.Item>
                ))
            }
        </React.Fragment>
    );

    const ChapterForm = ({ fields, remove }) => (
        <Collapse defaultActiveKey={fields.map((e, i) => `chap-${i}`)}>
            {
                fields.map((field, i) => (
                    <Panel
                        header={
                            <React.Fragment>
                                Chapter {i + 1}
                                {
                                    fields.length > 1 ?
                                        <Button shape="circle"
                                            danger className="ms-auto"
                                            icon={<DeleteOutlined />}
                                            onClick={() => remove(field.name)}
                                        />
                                        :
                                        null
                                }
                            </React.Fragment>
                        }
                        key={`chap-${i}`}
                    >
                        <Form.Item
                            wrapperCol={{ span: 24 }}
                            className="label-100"
                        >
                            <Form.Item
                                {...field}
                                {...formItemLayout}
                                label="Chapter Name"
                                className="label-text-right"
                                name={[field.name, 'name']}
                                key={`${i}-name`}
                                >
                                <Input />
                            </Form.Item>
                            <Form.List
                                wrapperCol={24}
                                name={[field.name, 'topics']}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        <TopicForm fields={fields} i={i} remove={remove} />
                                        <Form.Item className="btn-wrapper" wrapperCol={{ span: 24 }}>
                                            <Button
                                                type="primary"
                                                onClick={() => add()}
                                                icon={<PlusOutlined />}
                                                className="ms-auto"
                                            >
                                                Add Topic
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>
                    </Panel>
                ))
            }
        </Collapse>
    )

    return (
        <div className="container py-5 course-form">
            <div className="row">
                <div className="col-12 col-lg-9 offset-lg-1">

                    <h5 className="mb-4">
                        Add / Update Course
                    </h5>

                    <Form
                        name="user-info"
                        labelCol={{ xs: 24, md: 5 }}
                        wrapperCol={{ xs: 24, md: 19 }}
                        initialValues={course}
                        onFinish={submit}
                        autoComplete="off"
                    >
                        <Form.Item label='Course Name' name='name' >
                            <Input />
                        </Form.Item>
                        <Form.Item label='Banner link' name='banner_link'>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Introduction Video' name='intro_video'>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Description' name='description'>
                            <RichText />
                        </Form.Item>
                        <Form.Item label='Price' name='price'>
                            <InputNumber />
                        </Form.Item>
                        <Form.List
                            name="syllabus"
                        >
                            {(fields, { add, remove }) => (
                                <>
                                    <ChapterForm fields={fields} remove={remove} />
                                    <Form.Item wrapperCol={24} className="btn-wrapper mt-3">
                                        <Button
                                            className="ms-auto"
                                            type="primary"
                                            onClick={() => add()}
                                            icon={<PlusOutlined />}
                                        >
                                            Add Chapter
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <div className="d-flex w-100 my-4">
                            <Button
                                type='link' htmlType="button"
                                onClick={() => navigate('/dashboard')} className="ms-auto"
                            >
                                Cancel
                            </Button>
                            <Button type='primary' htmlType="submit" className="ms-3">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}